import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { PageName } from './app/config/app.constant';
import { AppConfig } from './app/config/app.config';

const toRightAnimationConfig = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translate3d(100%,0,0)' }),
        animate(AppConfig.ROUTER_TRANSITION_TIMING, style({ transform: 'translate3d(0,0,0)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translate3d(0,0,0)' }),
        animate(AppConfig.ROUTER_TRANSITION_TIMING, style({ transform: 'translate3d(-100%,0,0)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];

const toLeftAnimationConfig = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(
      ':enter',
      [
        style({ transform: 'translate3d(-100%,0,0)' }),
        animate(AppConfig.ROUTER_TRANSITION_TIMING, style({ transform: 'translate3d(0,0,0)' })),
      ],
      {
        optional: true,
      }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translate3d(0,0,0)' }),
        animate(AppConfig.ROUTER_TRANSITION_TIMING, style({ transform: 'translate3d(100%,0,0)' })),
      ],
      {
        optional: true,
      }
    ),
  ]),
];
export const routerTransition = trigger('routerTransition', [
  // Home <=> Users
  transition(`${PageName.Home} => ${PageName.Users}`, toRightAnimationConfig),
  transition(`${PageName.Users} => ${PageName.Home}`, toLeftAnimationConfig),
]);
